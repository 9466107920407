<template>
  <div class="d-flex align-items-center">
    <button
      type="button"
      class="
        btn
        text-hover-primary-1
        p-2
        ps-0
        fs-5
        d-flex
        gap-2
        align-items-center
      "
      v-for="(table, index) in tableOptions"
      :key="index"
      :class="{
        'text-primary-0': currentTable === table.value,
        'text-primary-1': currentTable !== table.value,
      }"
      @click="changeTable(table.value)"
    >
      {{ table.label }}
      <div
        class="line-table bg-primary-1"
        v-if="index + 1 !== tableOptions.length"
      ></div>
    </button>
  </div>
</template>
      
<script>
export default {
  props: {
    tableOptions: {
      type: Array,
      required: true,
    },
    currentTable: {
      type: String,
      required: true,
    },
    changeTable: {
      type: Function,
    },
  },
};
</script>