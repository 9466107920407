import { computed, onBeforeMount, ref } from "vue";
import { useStore } from "vuex";
import { ref as refDb, set, get, child, onValue } from "firebase/database";
import database from "@/firebase/config";

const useTeachers = () => {
  const store = useStore();
  const isLoadingBlock = ref(false);
  const setInfoStatusTeachers = () => {
    let { basicInfo } = store.getters["admins/getMembersTeachers"];
    basicInfo.data.forEach((teacher) => {
      const dbRef = refDb(
        database,
        "Teachers/" + teacher.token_firebase + "/status"
      );
      onValue(dbRef, async (snapshot) => {
        if (snapshot.val() === "offline") {
          teacher.status = "offline";
        } else {
          get(
            child(dbRef, "Teachers/" + teacher.token_firebase + "/last_status")
          )
            .then((snapshot2) => {
              if (
                snapshot2.val() !== "online" &&
                snapshot2.val() !== "offline"
              ) {
                teacher.status = snapshot.val();
              } else teacher.status = "online";
            })
            .catch((error) => {
              console.error(error);
            });
        }
        // store.commit("admins/setStudentsBasicInfo", { data: basicInfo.data });
      });
    });
  };

  
  const optionsReports = [
    {
      text: "Default",
      id: "default",
    },
    {
      text: "Teachers with the most effective classes",
      id: "teacher-more-classes",
      fields: ["id", "name"],
    },
    {
      text: "Teachers marked as favorite",
      id: "teacher-marked-favorite",
      fields: ["id", "name", "email", "tel"],
    },
  ];


  onBeforeMount(async () => {
    const { currentOptionReport, header } =
      store.getters["admins/getMembersTeachers"].basicInfo;
    if (!currentOptionReport || currentOptionReport === "default") {
      await store.dispatch("admins/getTeachers");
      setInfoStatusTeachers();
    } else {
      let tableHeader = header;
      const currentOption = optionsReports.find(
        (report) => report.id === currentOptionReport
      );

      if (currentOption) {
        await store.dispatch("admins/getTeachersByReport", {
          option: currentOptionReport,
        });
        tableHeader = tableHeader.filter((header) =>
          currentOption.fields.includes(header.field)
        );
        store.commit("admins/setTeachersBasicInfo", { header : tableHeader });
        setInfoStatusTeachers();
      }
    }
  });

  /* TABLES ----------------------------------------  */
  const tableOptions = ref([
    { label: "Basic info", value: "basic-info" },
    { label: "Account", value: "table-account" },
  ]);

  const currentTable = computed(() => {
    return store.getters["admins/getMembersTeachers"].currentTable;
  });

  const isOpenBlockTeacher = computed(() => {
    return store.getters["admins/toggleBlockTeacher"];
  });


  const changeTable = (currentTable) => {
    store.commit("admins/setCurrentTableTeachers", currentTable);
  };

  const toggleBlockTeacher = (id=null) => {
    const isOpen = store.getters["admins/toggleBlockTeacher"].isOpen
    if(!isOpen){
      store.commit("admins/toggleBlockTeacher",  { idTeacher : id });
    }
    store.commit("admins/toggleBlockTeacher",  { isOpen : !isOpen });
  }

  const blockTeacher = async () => {
    isLoadingBlock.value = true;
    const id = store.getters["admins/toggleBlockTeacher"].idTeacher
    const { success } = await store.dispatch("admins/blockTeacher", { id });
    if (success) {
      await store.dispatch("admins/getTeachers");
      setInfoStatusTeachers();
    }
    isLoadingBlock.value = false;
    toggleBlockTeacher()
  }

  return {
    toggleBlockTeacher,
    blockTeacher,
    currentTable,
    changeTable,
    isOpenBlockTeacher,
    isLoadingBlock,
    tableOptions,
  };
};

export default useTeachers;
