const roleUser = {
  Admin: {
    class: "bg-primary-1",
    classText: "text-white",
  },
  Coordinator: {
    class: "bg-accent-6",
    classText: "text-neutro-1",
  },
  Teacher: {
    class: "bg-accent-1",
    classText: "text-white",
  },
};

export default roleUser;
