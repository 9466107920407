import { ref, watchEffect } from "vue";
import { useStore  } from 'vuex'
const useMembers = () => {
  const store = useStore();
  const tableStudentsBasicInfo = ref(null);
  const tableTeachersBasicInfo = ref(null);
  const tableTeachersAccount = ref(null);
  const tableAdminsBasicInfo = ref(null);
  const tableAdminsAccount = ref(null);
  const fieldsExcelBasicStudents = ref({})
  const fieldsExcelBasicTeachers = ref({})
  const fieldsExcelAccountTeachers = ref({})
  const fieldsExcelBasicAdmins = ref({})
  const fieldsExcelAccountAdmins = ref({})
  const dataExcel = ref([])
  const loadingExport = ref(false)

  const openToast = (message, status) => {
    const notification = { isOpen: true };
    notification.message = message;
    notification.status = status;
    store.commit("shared/uiNotificationStatus", notification);
    store.dispatch("shared/resetNotificationStatus");
  };


  const exportDataElement = (className,tableRef) => {
    let elementRef = document.querySelector("."+className);
    tableRef.value.selection.length === 0 ? tableRef.value.processedData.length === 0 ? dataExcel.value = tableRef.value.value : dataExcel.value = tableRef.value.processedData : dataExcel.value = tableRef.value.selection;
   

    let id = setTimeout(() => {
      openToast(
        "The table selected has been exported successfully",
        "success"
      );
      elementRef.click();
      clearTimeout(id);
      loadingExport.value = false
    },3000)

  }

  const exportCsv = (type) => {
    loadingExport.value = true
    switch (type) {
      case "students-basic-info":
        const basicInfoStudents  = store.getters["admins/getMemberStudents"].tables.basicInfo;
        setHeader(basicInfoStudents.header,fieldsExcelBasicStudents)
        exportDataElement('basic-students',tableStudentsBasicInfo)
        break;
      case "teachers-basic-info":
      const basicInfoTeachers  = store.getters["admins/getMembersTeachers"].basicInfo;
        setHeader(basicInfoTeachers.header,fieldsExcelBasicTeachers)
        exportDataElement('basic-teachers',tableTeachersBasicInfo)
        break;
      case "teachers-account":
        const accountTeachers  = store.getters["admins/getMembersTeachers"].account;
        setHeader(accountTeachers.header,fieldsExcelAccountTeachers)
        exportDataElement('account-teachers',tableTeachersAccount)
        break;
      case "admins-basic-info":
        const basicInfoAdmins  = store.getters["admins/getMembersAdmins"].basicInfo;
        setHeader(basicInfoAdmins.header,fieldsExcelBasicAdmins)
        exportDataElement('basic-admins',tableAdminsBasicInfo)
        break;
      case "admins-account":
        const accountAdmins  = store.getters["admins/getMembersAdmins"].account;
        setHeader(accountAdmins.header,fieldsExcelAccountAdmins)
        exportDataElement('account-admins',tableAdminsAccount)
        break;
    }
  };

  const setHeader = (headers,fieldsExcel) => {
    headers.forEach(header => {
      fieldsExcel.value[header.name] = header.field
    })
  }


  return {
    exportCsv,
    loadingExport,
    dataExcel,
    tableStudentsBasicInfo,
    fieldsExcelBasicStudents,
    tableTeachersBasicInfo,
    tableTeachersAccount,
    tableAdminsBasicInfo,
    tableAdminsAccount,
  };
};

export default useMembers;
