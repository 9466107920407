import { computed, ref } from "vue";
import { useStore } from "vuex";
import { FilterMatchMode } from "primevue/api";

const useAccountInfoAdmin = () => {
  const store = useStore();
  const accountTable = computed(
    () => store.getters["admins/getMembersTeachers"].account
  );

  const accountHeader = ref(accountTable.value.header);
  const selectTeachers = ref(null);
  const accountInfoFilters = ref({
    global: { value: null, matchMode: FilterMatchMode.CONTAINS },
    name: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
    last_name: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
    email: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
    role: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
  });

  const isAccountInfoSearch = ref(false);
  const toggleAccountInfoSearch = () => {
    isAccountInfoSearch.value = !isAccountInfoSearch.value;
  };

  const filterOptions = ref();
  const showFilterOptions = (e) => {
    filterOptions.value.toggle(e);
  };

  const rangeDates = ref();

  return {
    accountTable,
    accountHeader,
    rangeDates,
    showFilterOptions,
    filterOptions,
    selectTeachers,
    accountInfoFilters,
    isAccountInfoSearch,
    toggleAccountInfoSearch,
  };
};

export default useAccountInfoAdmin;
