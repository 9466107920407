<template>
  <data-table
    :value="basicInfoTable.data"
    :rowHover="basicInfoTable.data.length > 0"
    :loading="basicInfoTable.isLoading"
    size="normal"
    ref="tableTeachersBasicInfo"
    responsiveLayout="scroll"
    :paginator="true"
    :rowsPerPageOptions="[20, 30, 40, 50]"
    :rows="20"
    v-model:selection="selectEmails"
    exportFilename="teachers-basic-info"
    @rowSelect="onSelectRow"
    @rowUnselect="onSelectRow"
    @rowSelectAll="onSelectAll"
    filterDisplay="menu"
    @rowUnSelectAll="unSelectAll"
    :globalFilterFields="[
      'name',
      'last_name',
      'email',
      'cellphone',
      'age',
      'gender',
      'birth',
      'role',
      'timezone',
      'code.dial_code',
      'status',
    ]"
    v-model:filters="basicInfoFilters"
  >
    <template #header>
      <div class="d-flex align-items-center justify-content-between mb-3">
        <div class="d-flex align-items-center gap-1">
          <options-table-component
            :tableOptions="tableOptions"
            :currentTable="currentTable"
            :changeTable="changeTable"
          />
          <div class="d-flex align-items-center gap-1">
            <input-text
              v-if="isBasicInfoSearch"
              placeholder="Search by name, email ..."
              class="rounded-0"
              v-model="basicInfoFilters['global'].value"
            />
            <i
              class="
                pi pi-search
                py-1
                px-3
                bg-primary-1
                text-white
                fs-5
                icon-search
                pointer
                border-r-10
                overflow-hidden
              "
              @click="toggleBasicInfoSearch"
            ></i>
          </div>
        </div>

        <div class="d-flex gap-3">
          <div class="field-outline-blue">
            <dropdown
              :options="optionsReports"
              optionLabel="text"
              optionValue="id"
              placeholder="Select report"
              :filter="true"
              v-model="basicInfoTable.currentOptionReport"
              class="bg-transparent"
              :showClear="true"
              @change="onChangeOptionReport"
            />
          </div>
          <div class="field-outline-blue w-100">
            <Calendar
              v-model="basicInfoTable.dateRanges" dateFormat="M dd"
              selectionMode="range" :manualInput="false" :showIcon="true" placeholder="MM/DD - MM/DD"
              :showButtonBar="true" @clear-click="clearDateRanges"
            />
          </div>
        </div>
      </div>
    </template>
    <template #empty> No Teachers found. </template>
    <template #loading> Loading Teachers data. Please wait. </template>
    <column selectionMode="multiple" headerStyle="width: 3rem"></column>

    <column
      v-for="(header, index) in basicInfoTable.header"
      :key="index"
      :field="header.field"
      :header="header.name"
      :sortable="header.sortable"
       :showFilterMatchModes="false"
      style="min-width: 14rem"
    >
      <template #body="{ data }">
        <p v-if="header.field !== 'status'">
          {{
            header.field === "cellphone"
              ? data.code
                ? data.code.dial_code + " " ?? ""
                : ""
              : ""
          }}

          {{ data[header.field] }}

          {{ header.field === "name" ? data.last_name ?? "" : "" }}
        </p>

        <router-link
          :to="'admin-members-teachers/edit-profile-teacher/' + data.id"
        >
          <button
            v-if="header.field === 'name'"
            type="button"
            class="btn text-secondary-1 text-hover-secondary-1 p-0 pt-1 px-0"
          >
            See profile
          </button>
        </router-link>

        <div
          class="
            bg-white
            py-1
            px-3
            bl-shadow
            d-flex
            gap-2
            rounded-pill
            w-content
            align-items-center
          "
          v-if="header.field === 'status'"
        >
          <div
            class="status"
            v-if="statusUsers[data.status]"
            :class="
              statusUsers[data.status] ? statusUsers[data.status].class : ''
            "
          ></div>
          <span class="text-neutro-1">{{
            statusUsers[data.status]
              ? statusUsers[data.status].text
              : "Loading .."
          }}</span>
        </div>
      </template>
       <template #filter="{ filterModel }" v-if="header.filter">
          <div class="d-block" v-if="header.field === 'status'">
            <div class="d-flex align-items-center mb-1 gap-2">
              <Checkbox name="status" value="online" v-model="filterModel.value" />
              <label>Online</label>
            </div>
            <div class="d-flex align-items-center mb-1 gap-2">
              <Checkbox name="status" value="offline" v-model="filterModel.value" />
              <label>Offline</label>
            </div>
          </div>
           <div class="d-block" v-if="header.field === 'gender'">
            <div v-for="(gender,index) in getListings.genders" :key="index" class="d-flex align-items-center mb-1 gap-2">
              <Checkbox name="status" :value="gender.value" v-model="filterModel.value" />
              <label>{{gender.value}}</label>
            </div>
          </div>
          <div class="d-block" v-if="header.field === 'age'">
            <div class="d-block">
              <input-text type="number" name="age" v-model="filterModel.value" placeholder="Search by age"/>
            </div>
          </div>
          <!-- <div class="d-block" v-if="header.field === 'working_hours'">
            <div class="d-flex align-items-center mb-1 gap-2">
              <Checkbox name="working" value="Partime" v-model="filterModel.value" />
              <label>Partime</label>
            </div>
            <div class="d-flex align-items-center mb-1 gap-2">
              <Checkbox name="working" value="Fulltime" v-model="filterModel.value" />
              <label>Fulltime</label>
            </div>
          </div> -->
        </template>
    </column>

    <template #paginatorstart>
      <div class="d-flex align-items-center justify-content-start gap-3">
        <Button
          class="p-button-rounded bg-primary-1 text-white border-0 m-0"
          icon="pi pi-send"
          v-tooltip.top="'Send email'"
          :disabled="selectEmails.length === 0"
          @click="() => emailModal(true)"
          style="width: 2.4rem"
        />
        <Button
          class="p-button-rounded bg-primary-1 text-white border-0 m-0"
          icon="pi pi-refresh"
          v-tooltip.top="'Reset password'"
          :disabled="selectEmails.length === 0"
          @click="() => cancelEmailResetModal(true)"
          style="width: 2.4rem"
        />
        <Button
          class="p-button-rounded bg-primary-1 text-white border-0 m-0"
          icon="pi pi-sort-alt"
          v-tooltip.top="'Export report'"
          @click="() => exportCsv('teachers-basic-info')"
          :disabled="loadingExport"
          style="width: 2.4rem"
        />
          <p v-if="selectEmails.length > 0">{{selectEmails.length}} selected from {{basicInfoTable.data.length}} records</p>

      </div>
    </template>
  </data-table>

  <export-excel
    class="btn btn-default d-none basic-teachers"
    :data="dataExcel"
    :fields="fieldsExcelBasicTeachers"
    worksheet="My Worksheet"
    name="filename.xls"
  >
  </export-excel>
</template>

<script>
import useTeachers from "@/modules/admin/composables/Members/Teachers/useTeachers";
import useResetEmail from "@/modules/admin/composables/Members/useResetEmail";
import useBasicInfoAdmin from "@/modules/admin/composables/Members/Teachers/useBasicInfoAdmin";
import OptionsTableComponent from "@/modules/admin/components/Members/OptionsTableComponent";
import statusUsers from "@/shared/data/statusUsers";
import roleUsers from "@/shared/data/rolesUsers";
import useSendEmail from "@/modules/admin/composables/Members/useSendEmail";
import useMembers from "@/modules/admin/composables/Members/useMembers";

export default {
  name: "TeachersBasicInfoTableComponent",
  components: {
    OptionsTableComponent,
  },
  setup() {
    return {
      ...useMembers(),
      ...useResetEmail(),
      ...useSendEmail(),
      ...useTeachers(),
      ...useBasicInfoAdmin(),
      statusUsers,
      roleUsers,
    };
  },
};
</script>

<style></style>
