<template>
  <div>
    <data-table :value="accountTable.data" :rowHover="accountTable.data.length > 0" :loading="accountTable.isLoading"
      size="normal" ref="tableTeachersAccount" responsiveLayout="scroll" :paginator="true"
      :resizableColumns="true" columnResizeMode="fit"
      :rowsPerPageOptions="[20, 30, 40, 50]" :rows="20" v-model:selection="selectEmails" @rowSelect="onSelectRow"
      exportFilename="teachers-account" @rowUnselect="onSelectRow" @rowSelectAll="onSelectAll"
      @rowUnSelectAll="unSelectAll" :globalFilterFields="['name', 'last_name', 'email', 'role']"
      v-model:filters="accountInfoFilters">
      <template #header>
        <div class="d-flex align-items-center justify-content-between mb-3">
          <div class="d-flex align-items-center gap-1">
            <options-table-component :tableOptions="tableOptions" :currentTable="currentTable"
              :changeTable="changeTable" />
            <div class="d-flex align-items-center gap-1">
              <input-text placeholder="Search by name, email ..." class="rounded-0" v-if="isAccountInfoSearch"
                v-model="accountInfoFilters['global'].value" />
              <i class="
                  pi pi-search
                  py-1
                  px-3
                  bg-primary-1
                  text-white
                  fs-5
                  icon-search
                  pointer
                  border-r-10
                  overflow-hidden
                " @click="toggleAccountInfoSearch"></i>
            </div>
          </div>
        </div>
      </template>
      <template #empty> No students found. </template>
      <template #loading> Loading students data. Please wait. </template>
      <column selectionMode="multiple" headerStyle="width: 3rem"></column>

      <column v-for="(header, index) in accountHeader" :key="index" :field="header.field" :header="header.name"
        :sortable="header.sortable" :showFilterMatchModes="false" style="min-width: 14rem">
        <template #body="{ data }">
          <p v-if="header.field !== 'role'">
            {{ data[header.field] }}
            {{ header.field === "name" ? data.last_name ?? "" : "" }}
          </p>
          <div class="px-2 py-1 w-content rounded-pill" v-if="header.field === 'role'" :class="[
            roleUsers[data.role].class,
            roleUsers[data.role].classText,
          ]">
            {{ data.role }}
          </div>
        </template>
      </column>
      <column header="Options" style="min-width: 14rem">
        <template #body="{ data }">
          <div class="d-flex gap-3 align-items-center justify-between">
            <router-link :to="'admin-members-teachers/edit-profile-teacher/' + data.id" class="
              btn
              text-primary-1 text-hover-primary-1
              bg-hover-primary-6
              border-primary-1
            ">
              Edit profile
            </router-link>
            <router-link :to="'admin-members-teachers/scheduled-job/' + data.id" class="
              btn
              text-primary-1 text-hover-primary-1
              bg-hover-primary-6
              border-primary-1
            ">
              Edit schedule
            </router-link>
            <button @click="() => toggleBlockTeacher(data.id)" class="
              btn
              text-primary-1 text-hover-primary-1
              bg-hover-primary-6
              border-primary-1
            ">
              <i :class="data.is_locked ? 'pi pi-user-plus' : 'pi pi-user-minus'"></i>
            </button>
          </div>
        </template>
      </column>
      <template #paginatorstart>
        <div class="d-flex align-items-center justify-content-start gap-3">
          <Button class="p-button-rounded bg-primary-1 text-white border-0 m-0" icon="pi pi-send"
            :disabled="selectEmails.length === 0" @click="() => emailModal(true)" v-tooltip.top="'Send email'"
            style="width: 2.4rem" />
          <Button class="p-button-rounded bg-primary-1 text-white border-0 m-0" icon="pi pi-refresh"
            v-tooltip.top="'Reset password'" :disabled="selectEmails.length === 0"
            @click="() => cancelEmailResetModal(true)" style="width: 2.4rem" />
          <Button class="p-button-rounded bg-primary-1 text-white border-0 m-0" icon="pi pi-sort-alt"
            v-tooltip.top="'Export report'" style="width: 2.4rem" @click="() => exportCsv('teachers-account')"
            :disabled="loadingExport" />
          <p v-if="selectEmails.length > 0">{{ selectEmails.length }} selected from {{ accountTable.data.length }}
            records
          </p>
        </div>
      </template>
    </data-table>
  </div>
  <export-excel class="btn btn-default d-none account-teachers" :data="dataExcel" :fields="fieldsExcelAccountTeachers"
    worksheet="My Worksheet" name="filename.xls">
  </export-excel>
</template>

<script>
import useTeachers from "@/modules/admin/composables/Members/Teachers/useTeachers";
import useAccountInfoAdmin from "@/modules/admin/composables/Members/Teachers/useAccountInfoAdmin";
import OptionsTableComponent from "@/modules/admin/components/Members/OptionsTableComponent";
import statusUsers from "@/shared/data/statusUsers";
import roleUsers from "@/shared/data/rolesUsers";
import useResetEmail from "@/modules/admin/composables/Members/useResetEmail";
import useSendEmail from "@/modules/admin/composables/Members/useSendEmail";
import useMembers from "@/modules/admin/composables/Members/useMembers";

export default {
  name: "TeachersAccountTableComponent",
  components: {
    OptionsTableComponent,
  },
  setup() {
    return {
      ...useMembers(),
      ...useTeachers(),
      ...useSendEmail(),
      ...useResetEmail(),
      ...useAccountInfoAdmin(),
      statusUsers,
      roleUsers,
    };
  },
};
</script>
<style>
</style>
