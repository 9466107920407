import { computed, ref } from "vue";
import { useStore } from "vuex";
import { FilterMatchMode } from "primevue/api";
import { ref as refDb, set, get, child, onValue } from "firebase/database";
import database from "@/firebase/config";
import filterDateRanges from "@/modules/admin/helpers/filterDateRanges";

const useBasicInfoAdmin = () => {
  const store = useStore();

  const basicInfoTable = computed(() => {
    const teachers = store.getters["admins/getMembersTeachers"].basicInfo;

    const datesRanges_ = teachers.dateRanges ?? [];
    const hasDateRanges = teachers.dateRanges.filter((date) => date).length === 2;
    if (hasDateRanges) {
      teachers.data = filterDateRanges({
        datesRanges: datesRanges_,
        data: teachers.data,
        fieldDate: "created_at",
      });
    }

    return teachers;
  });

  const clearDateRanges = async () => {
    store.commit("admins/setTeachersBasicInfo", { dateRanges: [] });
    const { currentOptionReport , header } = store.getters["admins/getMembersTeachers"].basicInfo;
    if(currentOptionReport === "default" || !currentOptionReport) {
      await store.dispatch("admins/getTeachers");
      store.commit("admins/setTeachersBasicInfo", { header : defaultHeader});
      setInfoStatusTeachers();
    }else{
      const currentOption = optionsReports.find(
        (report) => report.id === currentOptionReport
      );
      if (currentOption) {
        await store.dispatch("admins/getTeachersByReport", {
          option: currentOptionReport,
        });
        store.commit("admins/setTeachersBasicInfo", { header });
        setInfoStatusTeachers();
      }
    }
  };

  const selectTeachers = ref([]);

  const filterOptions = ref();
  const showFilterOptions = (e) => {
    filterOptions.value.toggle(e);
  };

  const basicInfoFilters = ref({
    global: { value: null, matchMode: FilterMatchMode.CONTAINS },
    name: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
    last_name: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
    email: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
    cellphone: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
    "code.dial_code": { value: null, matchMode: FilterMatchMode.STARTS_WITH },
    age: { value: "", matchMode: FilterMatchMode.IN },
    gender: { value: [], matchMode: FilterMatchMode.IN },
    birth: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
    timezone: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
    status: { value: [], matchMode: FilterMatchMode.IN },
  });
  const isBasicInfoSearch = ref(false);
  const toggleBasicInfoSearch = () => {
    isBasicInfoSearch.value = !isBasicInfoSearch.value;
  };

  const optionsReports = [
    {
      text: "Default",
      id: "default",
    },
    {
      text: "Teachers with the most effective classes",
      id: "teacher-more-classes",
      fields: ["id", "name"],
    },
    {
      text: "Teachers marked as favorite",
      id: "teacher-marked-favorite",
      fields: ["id", "name", "email", "tel"],
    },
  ];

  const setInfoStatusTeachers = () => {
    let { basicInfo } = store.getters["admins/getMembersTeachers"];
    basicInfo.data.forEach((teacher) => {
      const dbRef = refDb(
        database,
        "Teachers/" + teacher.token_firebase + "/status"
      );
      onValue(dbRef, async (snapshot) => {
        if (snapshot.val() === "offline") {
          teacher.status = "offline";
        } else {
          get(
            child(dbRef, "Teachers/" + teacher.token_firebase + "/last_status")
          )
            .then((snapshot2) => {
              if (
                snapshot2.val() !== "online" &&
                snapshot2.val() !== "offline"
              ) {
                teacher.status = snapshot.val();
              } else teacher.status = "online";
            })
            .catch((error) => {
              console.error(error);
            });
        }
        store.commit("admins/setTeachersBasicInfo", { data: basicInfo.data });
      });
    });
  };

  const defaultHeader = [
    { field: "status", name: "Status", sortable: true , filter : true },
    { field: "id", name: "Teacher ID", sortable: false },
    { field: "name", name: "Name(s)", sortable: false },
    { field: "email", name: "Email", sortable: false },
    { field: "cellphone", name: "Phone", sortable: false },
    { field: "age", name: "Age", sortable: true , filter : true },
    { field: "birth", name: "Birthday", sortable: false },
    { field: "gender", name: "Gender", sortable: true , filter : true },
    { field: "time_zone_change", name: "Time Zone", sortable: true },
    { 
      field: "cant_classes_completed",
      name: "Number of classes completed",
      sortable: false,
    },
    {
      field: "number_times_marked",
      name: "Number of times marked as favorite",
      sortable: false,
    },
  ];

  const onChangeOptionReport = async (option) => {
    if (option.value === "default" || !option.value) {
      await store.dispatch("admins/getTeachers");
      store.commit("admins/setTeachersBasicInfo", { header: defaultHeader });
      setInfoStatusTeachers();
      return;
    }
    let { header } = store.getters["admins/getMembersTeachers"].basicInfo;
    const currentOption = optionsReports.find(
      (report) => report.id === option.value
    );

    if (currentOption) {
      await store.dispatch("admins/getTeachersByReport", {
        option: option.value,
      });
      header = defaultHeader.filter((header) =>
        currentOption.fields.includes(header.field)
      );
      header = header.filter((header) =>
        currentOption.fields.includes(header.field)
      );
      store.commit("admins/setTeachersBasicInfo", { header });
      setInfoStatusTeachers();
    }
  };

  const getListings = computed(() => {
    return store.getters["shared/getListings"];
  })


  return {
    getListings,
    basicInfoTable,
    showFilterOptions,
    filterOptions,
    selectTeachers,
    isBasicInfoSearch,
    toggleBasicInfoSearch,
    basicInfoFilters,
    optionsReports,
    onChangeOptionReport,
    clearDateRanges,
  };
};
export default useBasicInfoAdmin;
