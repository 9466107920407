import { computed, ref } from "vue";
import { useStore } from "vuex";

const useResetEmail = () => {
  const store = useStore();
  const isSending = ref(false);
  const selectEmails = ref([]);
  const emailResetModal = computed(
    () => store.getters["admins/memberEmailResetModal"]
  );

  const cancelEmailResetModal = (isOpen) => {
    store.commit("admins/setResetEmailModal", { isOpen });
  };

  const onSelectRow = (e) => {
    let emails = [];
    selectEmails.value.forEach((email) => {
      emails.push(email.email);
    });
    console.log(selectEmails.value);
    store.commit("admins/resetEmails", { emails });
    store.commit("admins/sendEmails", { emails });
  };

  const onSelectAll = (e) => {
    let emails = [];
    selectEmails.value = e.data;
    selectEmails.value.forEach((email) => {
      emails.push(email.email);
    });
    store.commit("admins/resetEmails", { emails });
    store.commit("admins/sendEmails", { emails });
  };

  const unSelectAll = () => {
    selectEmails.value = [];
    store.commit("admins/resetEmails", { emails: [] });
    store.commit("admins/sendEmails", { emails: [] });
  };

  const sendEmailsReset = async () => {
    isSending.value = true;
    const { emails } = store.getters["admins/memberEmailResetModal"];
    await store.dispatch("admins/sendEmailReset", { payload: emails });
    selectEmails.value = [];
    isSending.value = false;
  };

  const resetPasswordEmails = () => {
    store.commit("admins/setResetEmailModal", { isOpen: false });
  };

  return {
    unSelectAll,
    emailResetModal,
    sendEmailsReset,
    cancelEmailResetModal,
    resetPasswordEmails,
    selectEmails,
    onSelectAll,
    isSending,
    onSelectRow,
  };
};

export default useResetEmail;
