<template>
  <div>
    <div class="d-flex align-items-center justify-content-between mb-2">
      <h1 class="text-primary-1 fs-1 fw-bold">Teachers</h1>
      <button
        class="
          btn
          text-secondary-1
          border-secondary-1
          bg-hover-light-secondary-1
          py-2
          px-4
        "
        @click="toggleTeacherModal({ isOpen: true, status: 'add' })"
      >
        <i class="pi pi-plus me-2"></i>
        Add teacher
      </button>
    </div>

    <!-- BasicInfoTableComponent -->
    <basic-info-table-component v-if="currentTable === 'basic-info'" />
    <account-table-component v-if="currentTable === 'table-account'" />
  </div>
</template>

<script>
import useTeachers from "@/modules/admin/composables/Members/Teachers/useTeachers";
import useTeacherAddOrUpdate from "@/modules/admin/composables/Members/Teachers/useTeacherAddOrUpdate";
import BasicInfoTableComponent from "@/modules/admin/components/Members/Teachers/BasicInfoTableComponent";
import AccountTableComponent from "@/modules/admin/components/Members/Teachers/AccountTableComponent";
import OptionsTableComponent from "@/modules/admin/components/Members/OptionsTableComponent";
import statusUsers from "@/shared/data/statusUsers";
import roleUsers from "@/shared/data/rolesUsers";

export default {
  name: "MemberTeacherView",
  components: {
    OptionsTableComponent,
    BasicInfoTableComponent,
    AccountTableComponent,
  },
  setup() {
    const { toggleTeacherModal } = useTeacherAddOrUpdate();
    return {
      ...useTeachers(),
      toggleTeacherModal,
      statusUsers,
      roleUsers,
    };
  },
};
</script>

<style lang="scss" scoped>
</style>