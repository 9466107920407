import { computed, ref, watchEffect } from "vue";
import { useStore } from "vuex";

const useSendEmail = () => {
  const store = useStore();
  const isSendingEmails = ref(false);
  const payload = ref({
    data: "",
    templateName: "",
    emailSelects: [],
    emailSelectsText: "",
  });
  const isSavingTemplate = ref(false);
  const sendEmailModal = computed(
    () => store.getters["admins/memberEmailsModal"]
  );

  const emailModal = (isOpen) => {
    store.commit("admins/setSendEmailModal", { isOpen });
  };

  const cancelSendEmails = () => {
    store.commit("admins/setSendEmailModal", { isOpen: false });
  };

  const sendEmails = async () => {
    isSendingEmails.value = true;
    const body = parseBodyImg(payload.value.data)
    await store.dispatch("admins/sendEmails", {
      payload: { 
        emails: payload.value.emailSelects, 
        body: body.body,
        images:body.images,
        title: payload.value.templateName
      },
    });
    isSendingEmails.value = false;
  };

  const parseBodyImg = (str) => {
    let mail = str
    const stringToHTML = function (str) {
      const parser = new DOMParser();
      const doc = parser.parseFromString(str, 'text/html');
      return doc.body;
    };
    
    const html = stringToHTML(mail)
    const imgs = html.querySelectorAll("img")
    const imgUrls = []
    
    if(imgs.length){
      imgs.forEach((item)=>{
        imgUrls.push(item.src)
        mail = mail.replace('src="'+item.src+'"', '')
      })
    }
    
    return {
      body: mail,
      images: imgUrls
    }
  }

  const selectTemplate = (e) => {
    const templateSelect = store.getters["admins/emailTemplates"].data.find( p => p.name === e.value);
    payload.value.data = templateSelect.body;
  }

  const createTemplateEmail = async () => {
    if(templateEmails.value.data.some(item => item.name === payload.value.templateName)){
      store.commit("shared/uiNotificationStatus", {
        status: "error",
        message: "There is already a template with this name, please choose a different one.",
        isOpen: true,
      });
      return
    }
    isSavingTemplate.value = true;
    const { success } = await store.dispatch("shared/createTemplateEmail", {
      payload: { 
        body: payload.value.data,
        name: payload.value.templateName
      },
    });
    if(success){
      await store.dispatch("shared/getListings");
      await store.dispatch("admins/emailTemplates")

    }
    isSavingTemplate.value = false;
  } 

  watchEffect(() => {
    let { emails } = store.getters["admins/memberEmailsModal"];
    payload.value.emailSelects = emails;
    payload.value.emailSelectsText = "";
    emails.forEach((email, index) => {
      payload.value.emailSelectsText +=
        index !== emails.length - 1 ? email + "," : email;
    });
  });

  const templateEmails = computed(() => {
    return store.getters["admins/emailTemplates"];
  });

  return {
    sendEmailModal,
    createTemplateEmail,
    templateEmails,
    cancelSendEmails,
    sendEmails,
    payload,
    emailModal,
    isSendingEmails,
    isSavingTemplate,
    selectTemplate
  };
};

export default useSendEmail;
